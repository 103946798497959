import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BILLING_CHARGE_URL,
  IMAGE_DNS,
  VFUN_IMAGE_DNS
} from "utils/config/Config";
import { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  emailAuthStatus,
  headersState,
  isAuthConfirmState,
  isLauncherState,
  loginState,
  userIdState,
  userNicknameState,
  userProfileKeyState
} from "atoms/userInfoAtom";
import { menuOpenState } from "atoms/uiAtom";
import { redirectURL } from "utils/CommonFunc";
import LoginBtn from "components/common/LoginBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronLeft,
  faAngleDown,
  faSpinner,
  faWallet
} from "@fortawesome/free-solid-svg-icons";
import CombineSideMenu from "./CombineSideMenu/CombineSideMenu";
import SwitchLanguageTab from "components/common/SwitchLanguageTab";
import { useCookies } from "react-cookie";
import { useQuery } from "react-query";
import { getGrade } from "api/api";
import { useMediaQuery } from "react-responsive";
import VWallet from "components/common/VWallet/VWallet";
import { useLogout } from "hooks/useLogout";

function CombineMenu() {
  const { t } = useTranslation();
  const userId = useRecoilValue(userIdState);
  const userNickname = useRecoilValue(userNicknameState);
  const isLogin = useRecoilValue(loginState);
  const [openSideMenu, setOpenSideMenu] = useRecoilState(menuOpenState);
  const [isWalletOpen, setIsWalletOpen] = useState(false);
  const setIsAuthConfirm = useSetRecoilState(isAuthConfirmState);
  const [cookies, setCookie, removeCookie] = useCookies();
  const service_code = cookies["service_code"];
  const isLauncher = useRecoilValue(isLauncherState);
  const headers = useRecoilValue(headersState);
  const emailAuth = useRecoilValue(emailAuthStatus);
  const userProfileKey = useRecoilValue(userProfileKeyState);
  const navigate = useNavigate();
  const Logout = useLogout();

  const { isLoading, data } = useQuery(["grade"], () => getGrade(headers), {
    enabled: isLogin
  });

  const goBack = (e: React.MouseEvent): void => {
    e.preventDefault();
    navigate(-1);
  };

  const onClickUserId = (e: React.MouseEvent) => {
    e.preventDefault();
    if (emailAuth === "Y") {
      navigate("/membership/myinfo");
      return;
    }

    setIsAuthConfirm(false);
    redirectURL(
      `${process.env.REACT_APP_PLATFORM_DNS}/membership/sns_email_auth_confirm`,
      "_self",
      isLauncher
    );
  };

  const onClickLogo = () => {
    redirectURL(
      process.env.REACT_APP_PLATFORM_DNS || "https://vfun.valofe.com",
      "_self",
      isLauncher
    );
    window.localStorage.removeItem("prevPath");
  };

  const width768 = useMediaQuery({
    query: "screen and (max-width: 768px)"
  });

  return (
    <div id="combineMenu" className="clearfix">
      <div className="wrap-cbm clearfix" id="page-wrap">
        {/* header-left */}
        <ul className="cb-menu pull-left">
          <li className="cbm-logo-li">
            <Link
              to={window.location.href}
              className="cbm-logo"
              rel="noreferrer"
              title="vfun-logo"
              onClick={onClickLogo}
            />
          </li>
          <li className="cbm-back">
            <Link to="#" onClick={goBack}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Link>
          </li>
          {!width768 && (
            <li className="cbm-bar">
              <Link
                to={window.location.href}
                className="cbm-game"
                title="side-menu"
              >
                <FontAwesomeIcon
                  icon={faBars}
                  onClick={() => setOpenSideMenu(true)}
                />
              </Link>
            </li>
          )}
        </ul>

        {/* SideMenu */}
        {openSideMenu ? <CombineSideMenu /> : null}

        <img
          src={`${VFUN_IMAGE_DNS}/web/vfun/images/logo/logo-vfun.png`}
          alt="vfun-logo"
          className="middle-vfun-logo"
          onClick={() => navigate("/")}
        />

        {/* header-right */}
        <fieldset>
          <legend className="hidden">LOGIN FORMULAR</legend>

          <SwitchLanguageTab />
          {!isLogin ? (
            // 로그인 전 header-right
            <LoginBtn addclassname="login-area" />
          ) : (
            // 로그인 후 header-right
            <ul className="login-area pull-right">
              {/* 유저 정보 */}
              <li className="text user-id">
                <Link to={window.location.href} title={t("my_info")}>
                  {!isLoading ? (
                    data?.data.result === 1 ? (
                      <img
                        src={`${VFUN_IMAGE_DNS}/web/vfun/images/common/grade/${data.data.data}`}
                        alt="g-new"
                        onClick={() =>
                          redirectURL(
                            BILLING_CHARGE_URL(service_code),
                            "_self",
                            isLauncher
                          )
                        }
                      />
                    ) : null
                  ) : (
                    <FontAwesomeIcon icon={faSpinner} pulse />
                  )}
                  <span className="text-id" onClick={onClickUserId}>
                    {userNickname === "" ? userId : userNickname}
                  </span>
                </Link>
              </li>

              <li className="profile-btn">
                <Link
                  to={window.location.href}
                  onClick={() =>
                    redirectURL(
                      `${process.env.REACT_APP_LOUNGE_DNS}/space/${userProfileKey}`,
                      "_self",
                      isLauncher
                    )
                  }
                >
                  <span className="my-space-btn">My Space</span>
                </Link>
              </li>

              {/* V Wallet */}
              <li className="text user-coin">
                <span
                  className="wallet-click"
                  onClick={() => setIsWalletOpen((prev) => !prev)}
                >
                  <FontAwesomeIcon icon={faWallet} />
                  V Wallet
                  <FontAwesomeIcon icon={faAngleDown} />
                </span>
                <VWallet isWalletOpen={isWalletOpen} className="tV" />
              </li>

              <li className="login">
                <Link to={window.location.href} onClick={Logout}>
                  {t("signout")}
                </Link>
              </li>
            </ul>
          )}
        </fieldset>
      </div>
    </div>
  );
}

export default withTranslation()(CombineMenu);
