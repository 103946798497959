import { Service_type } from "../CommonFunc";

//// 사이트 정보 키
export const SERVICE_CODE = "vfun";
export const VERIFY_KEY: string = "ehG.NSdz.qoffhvm!@";
export const ReCAPTCHA_KEY = "6LdXlUgUAAAAAOCZNJrrSYc8xOVZOn8ZcCnRs-7e";

//// UI
export const IMAGE_DNS: string = "https://file.qijisoft.com";
export const VFUN_IMAGE_DNS: string = "https://file.qijisoft.com/Valofe_file";

//// 사용자 정보
export const MY_INFO_URL: string =
  process.env.REACT_APP_MEMBER_DNS +
  `${
    Service_type
      ? "/membership/myinfo?service_code=" +
        SERVICE_CODE +
        "&ret=" +
        window.location.href
      : "/mypage/mypage_auth.asp"
  }`;

// export const SIGN_IN_URL: string =
//   process.env.REACT_APP_MEMBER_DNS +
//   `${
//     Service_type
//       ? "/membership/signin?service_code=" +
//         SERVICE_CODE +
//         "&ret=" +
//         window.location.href
//       : "/login/login.asp?ret=" + window.location.href
//   }`;

export const SIGN_IN_URL = (service_code: string) =>
  `/membership/signin?service_code=${service_code}&ret=${window.location.href}`;

// export const SIGN_UP_URL: string =
//   process.env.REACT_APP_MEMBER_DNS +
//   `${
//     Service_type
//       ? "/membership/signup?service_code=" +
//         SERVICE_CODE +
//         "&ret=" +
//         window.location.href
//       : "/join/member_join_pre.asp"
//   }`;

export const SIGN_UP_URL = (service_code: string) =>
  `/membership/signup?service_code=${service_code}&ret=${window.location.href}`;

//// 이동 URL
export const BILLING_CHARGE_URL = (service_code: string) => {
  return (
    process.env.REACT_APP_VFUN_API_DNS +
    `${
      Service_type
        ? `/api/common/redirect/billing?service_code=${service_code}&type=purchase`
        : "/mycash/charge_info.asp"
    }`
  );
};

export const PRIVACY_POLICY_URL: string =
  process.env.REACT_APP_PLATFORM_DNS +
  "/customer/g_pc_privacy?service_code=vfun&amplanguage=en";

export const TERMS_OF_AGREEMENT_URL: string =
  process.env.REACT_APP_PLATFORM_DNS +
  "/customer/g_pc_terms?service_code=vfun&amplanguage=en";

export const YOUTUBE_URL: string =
  "https://www.youtube.com/channel/UCBOhYvVCI0InwSoyCWsbPZw/videos";

export const VFUN_SITE: string = "https://www.valofe.com";

export const LAUNCHER_DOWN: string =
  "https://vfun-cdn.qijisoft.com/vlauncher/fullclient/VFUNLauncherInstaller.exe";
