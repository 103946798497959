import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MembershipWrap from "components/common/Wrap/MembershipWrap";
import InputWrap from "components/MembershipComponent/InputWrap";
import { t } from "i18next";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  inputValueState,
  isValidState,
  validTextState
} from "atoms/userInfoAtom";
import { dateFormType1 } from "utils/CommonFunc";
import axios from "axios";
import {
  IUserData,
  IUserInfo,
  nickNameApi,
  snsSigninProcApi
} from "api/authApi";
import { useCookies } from "react-cookie";
import { wrapLoadingState } from "atoms/uiAtom";

function SnsSignUp() {
  const { state }: IUserInfo = useLocation();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useRecoilState(inputValueState);
  const [validText, setValidText] = useRecoilState(validTextState);
  const [isValid, setIsValid] = useRecoilState(isValidState);
  const [nameFocus, setIsNameFocus] = useState(false);
  const [nameVolid, setNameVolid] = useState(false);
  const [birthVolid, setBirthVolid] = useState(false);
  const [receiveLetter, setReceiveLetter] = useState(false);
  const [cookies, setCookie] = useCookies();
  const setIsLoading = useSetRecoilState(wrapLoadingState);
  const [nickNameCheck, setNickNameCheck] = useState(false);

  useEffect(() => {
    if (state === null) {
      alert(t("my_inquiry_desc13"));
      navigate("/membership/signup");
    }
  }, []);

  useEffect(() => {
    if (state !== null) {
      if (state.first_name !== "" && state.last_name !== "") {
        setNameVolid(true);
      }
      if (state.birthday !== "") {
        setBirthVolid(true);
      }
    }
  }, [state]);

  useEffect(() => {
    if (nameVolid && birthVolid) {
      setIsValid({ ...isValid, name: true, birth: true });
    } else if (nameVolid && !birthVolid) {
      setIsValid({ ...isValid, name: true, birth: false });
    } else if (!nameVolid && birthVolid) {
      setIsValid({ ...isValid, name: false, birth: true });
    } else {
      setIsValid({ ...isValid, name: false, birth: false });
    }
  }, [nameVolid, birthVolid]);

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  // 이름
  useEffect(() => {
    const nameExp = /^[a-zA-Z]*$/;
    if (state !== null) {
      if (state.first_name === "" && state.last_name === "") {
        if (
          nameExp.test(inputValue.firstName) &&
          nameExp.test(inputValue.lastName)
        ) {
          setValidText({ ...validText, name: "" });
          setIsValid({ ...isValid, name: true });
        } else {
          setValidText({
            ...validText,
            name: `${t("sns_signup_alert_only_alphabet")}`
          });
          setIsValid({ ...isValid, name: false });
        }
      }
    }
  }, [inputValue.firstName, inputValue.lastName, state]);

  const nameOnBlur = () => {
    let contLen =
      inputValue.firstName.length === 0 || inputValue.lastName.length === 0;
    if (contLen) {
      setValidText({
        ...validText,
        name: `${t("login_user_name_missing")}`
      });
    }
    setIsNameFocus(false);
  };

  // 생년월일
  useEffect(() => {
    // const birthExp = /^[0-9]{8}$/;
    const birthExp = /^[\d-]{10}$/;
    if (!birthExp.test(inputValue.birth)) {
      setIsValid({ ...isValid, birth: false });
    } else {
      const today = new Date();
      const birth = new Date(Date.parse(inputValue.birth));
      const limitDate: Date = new Date(
        today.getFullYear() - 14,
        today.getMonth(),
        today.getDate()
      );

      if (Number.isNaN(birth.getTime())) {
        setIsValid({ ...isValid, birth: false });
      } else {
        if (birth > limitDate) {
          setIsValid({ ...isValid, birth: false });
          setValidText({
            ...validText,
            birth: `${t("gdpr_birth")}`
          });
        } else {
          setIsValid({ ...isValid, birth: true });
          setValidText({ ...validText, birth: "" });
        }
      }
    }
  }, [inputValue.birth]);

  const birthOnBlur = () => {
    if (!isValid.birth) {
      if (inputValue.birth.length === 0) {
        setValidText({
          ...validText,
          birth: `${t("signup_birth_missing")}`
        });
      } else {
        setValidText({
          ...validText,
          birth: `${t("signup_birth_error")}`
        });
      }
    } else {
      setValidText({ ...validText, birth: `` });
    }
  };

  // 생년월일에 하이픈 기호 넣기
  useEffect(() => {
    const value = inputValue.birth.replace(/\D+/g, "");
    const numberLength = 8;

    let result;
    result = "";

    for (let i = 0; i < value.length && i < numberLength; i++) {
      switch (i) {
        case 4:
          result += "-";
          break;
        case 6:
          result += "-";
          break;
        case 8:
          result += "-";
          break;

        default:
          break;
      }

      result += value[i];
    }

    setInputValue({ ...inputValue, birth: result });
  }, [inputValue.birth]);

  // 닉네임 유효성
  useEffect(() => {
    const nickNameExp = /^[0-9A-Za-z][0-9A-Za-z_]{3,12}$/i;
    if (nickNameExp.test(inputValue.nickName)) {
      setNickNameCheck(true);
    } else {
      setNickNameCheck(false);
    }
  }, [inputValue.nickName]);

  const nickNameOnBlur = async () => {
    if (nickNameCheck) {
      const fetchApi = async () => {
        try {
          let res = await axios.get(nickNameApi(inputValue.nickName));
          if (res.data.result === 1) {
            setIsValid({ ...isValid, id: true });
          } else {
            setValidText({
              ...validText,
              id: `${t("already_registered_nickname")}`
            });
            setIsValid({ ...isValid, id: false });
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchApi();
    } else if (inputValue.nickName.length === 0) {
      setValidText({ ...validText, id: `${t("please_enter_a_nickname")}` });
    } else {
      setValidText({
        ...validText,
        id: `${t("alert_input_id_chr_rule")}`
      });
    }
  };

  useEffect(() => {
    if (isValid.id && isValid.name && isValid.birth) {
      setIsValid({ ...isValid, isNext: true });
    } else {
      setIsValid({ ...isValid, isNext: false });
    }
  }, [isValid.id, isValid.name, isValid.birth]);

  const signUpCheck = async () => {
    if (isValid.isNext) {
      setIsLoading(true);
      try {
        let res = await axios.post(snsSigninProcApi, {
          user_f_name:
            state.first_name !== "" ? state.first_name : inputValue.firstName,
          user_l_name:
            state.last_name !== "" ? state.last_name : inputValue.lastName,
          user_birth:
            state.birthday !== ""
              ? dateFormType1(state.birthday)
              : inputValue.birth,
          user_email: state.email,
          receive_letter: receiveLetter ? "Y" : "N",
          nickname: inputValue.nickName,
          user_id: state.id,
          sns_type: state.type,
          site_code: cookies["service_code"] || "vfun"
        });
        const result = res.data.result;
        if (result === 1) {
          const data: IUserData = res.data.data;
          setIsValid({ ...isValid, isSignIn: true });
          document.cookie = `sso_info_new=${data.sso_info_new}; path=/; domain=.valofe.com`;
          document.cookie = `sso_info=${data.sso_info}; path=/; domain=.valofe.com`;
          document.cookie = `gender=${
            data.gender.toUpperCase() === "M" ||
            data.gender.toUpperCase() === "F"
              ? data.gender
              : "+"
          }; path=/; domain=.valofe.com`;
          document.cookie = `email=${data.email}; path=/; domain=.valofe.com`;
          setCookie("birthday", data.birthday, {
            path: "/",
            domain: ".valofe.com"
          });
          setCookie("service_code", data.service_code, {
            path: "/",
            domain: ".valofe.com"
          });
          setCookie("userid", data.user_id, {
            path: "/",
            domain: ".valofe.com"
          });
          setCookie("SSOKey", data.SSOKey, {
            path: "/",
            domain: ".valofe.com"
          });
          setCookie("country", data.country, {
            path: "/",
            domain: ".valofe.com"
          });
          setCookie("auth", data.auth, {
            path: "/",
            domain: ".valofe.com"
          });
          setCookie("member_type", data.member_type, {
            path: "/",
            domain: ".valofe.com"
          });
          setCookie("check_email", data.check_email, {
            path: "/",
            domain: ".valofe.com"
          });
          setIsLoading(false);
          alert(t("signup_complete_desc1"));
          navigate("/");
        } else if (result === -88) {
          setIsLoading(false);
          alert("차단된 아이피입니다.");
          navigate("/membership/signin?service_code=vfun");
        } else if (result === -5) {
          setIsLoading(false);
          alert(t("vfun_error_leave_user"));
          navigate("/membership/signin?service_code=vfun");
        } else {
          setIsLoading(false);
          alert(t("my_inquiry_desc13"));
          navigate("/membership/signin?service_code=vfun");
        }
      } catch (error) {
        console.log("sns", error);
      }
    }
  };

  const onClickSignUp = () => {
    signUpCheck();
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (isValid.isNext) {
      if (e.key === "Enter") {
        e.preventDefault();
        signUpCheck();
      }
    }
  };

  return state !== null ? (
    <MembershipWrap title="signup" mb="mb45">
      <div className="cont on" id="cont_1">
        <p className="txt-info">{t("sns_signup_step1_desc")}</p>
        <form onKeyDown={onKeyDown}>
          {/* 이름 */}
          <InputWrap
            htmlFor="firstname, lastname"
            className={`border-type  ${nameFocus ? "on" : ""} clearfix`}
          >
            <input
              type="text"
              className="border-none-type width60"
              placeholder={t("f_name")}
              name="firstName"
              value={
                state.first_name !== ""
                  ? state.first_name
                  : inputValue.firstName
              }
              onChange={onValueChange}
              onBlur={nameOnBlur}
              readOnly={state.first_name !== "" ? true : false}
              onFocus={() => setIsNameFocus(true)}
            />
            <input
              type="text"
              className="border-none-type width40"
              placeholder={t("l_name")}
              name="lastName"
              value={
                state.last_name !== "" ? state.last_name : inputValue.lastName
              }
              onChange={onValueChange}
              onBlur={nameOnBlur}
              readOnly={state.last_name !== "" ? true : false}
              onFocus={() => setIsNameFocus(true)}
            />
            <span className="lastname-bar" />
          </InputWrap>
          <span className="alert round">{validText.name}</span>

          {/* 이메일 */}
          <InputWrap htmlFor="email">
            <input
              type="email"
              className=""
              placeholder={t("email")}
              name="email"
              value={state.email}
              readOnly={true}
            />
            <span className={`alert round on green`}>
              {t("sns_signup_alert_auth_email")}
            </span>
          </InputWrap>

          {/* 생일 */}
          <InputWrap htmlFor="birth">
            <input
              type="text"
              aria-label="Date-Time"
              placeholder={t("birth_day")}
              maxLength={10}
              name="birth"
              value={
                state.birthday !== ""
                  ? dateFormType1(state.birthday)
                  : inputValue.birth
              }
              onChange={onValueChange}
              onBlur={birthOnBlur}
              readOnly={state.birthday !== "" ? true : false}
            />
            <span className="alert round on">{validText.birth}</span>
          </InputWrap>

          {/* 닉네임 */}
          <InputWrap htmlFor="nickname">
            <input
              type="text"
              name="nickName"
              className=""
              onChange={onValueChange}
              onBlur={nickNameOnBlur}
              value={inputValue.nickName}
              placeholder="Nick Name"
              maxLength={10}
            />
            <span className="alert round">{validText.id}</span>
          </InputWrap>

          {/* 서비스 메일 구독 */}
          <InputWrap
            className="mt20 clearfix label-chk-sns"
            htmlFor="receive_letter"
          >
            <input
              type="checkbox"
              name="receive_letter"
              className="square-chk"
              onChange={() => setReceiveLetter((prev) => !prev)}
              checked={receiveLetter}
            />
            {t("receive_newsletter")}
          </InputWrap>

          <div className="wrap-btn mt50 mb60">
            <Link
              to={window.location.href}
              className={`btn _2 round-type ${
                isValid.isNext ? "valid-type-pass" : "valid-type-not"
              } on`}
              onClick={onClickSignUp}
            >
              {t("confirm")}
            </Link>
          </div>
        </form>
      </div>
    </MembershipWrap>
  ) : null;
}

export default SnsSignUp;
