import { useEffect } from "react";

interface IProps {
  children: React.ReactNode;
  state: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  type?: string;
}

function PopUpWrap(props: IProps) {
  const popUpStyle = {
    display: props.state ? "block" : "none",
    opacity: props.state ? 1 : 0
  };

  const shadowStyle = {
    display: props.state ? "block" : "none",
    opacity: props.state ? 1 : 0
  };

  useEffect(() => {
    if (props.state) {
      document.body.style.overflow = "hidden";
    }
  }, [props.state]);

  return (
    <div className={`view-popup ${props.type}`} style={popUpStyle}>
      <div
        className="view-shadow"
        style={shadowStyle}
        onClick={() => props.setState(false)}
      />
      <div className="pop-sizing event">
        <div className="pop-cont">
          <div id="viewUtil">
            <button className="x-btn" onClick={() => props.setState(false)} />
          </div>
          <div className="pop-article">{props.children}</div>
        </div>
      </div>
    </div>
  );
}

export default PopUpWrap;
