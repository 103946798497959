import { Helmet } from "react-helmet-async";

function VfunHelmet(props: { title: string }) {
  return (
    <Helmet>
      <title>VFUN - {props.title}</title>
    </Helmet>
  );
}

export default VfunHelmet;
