import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userInfoState } from "atoms/userInfoAtom";
import axios from "axios";
import { changeEmailCheckApi } from "api/authApi";
import { useCookies } from "react-cookie";
import { t } from "i18next";

function SnsChangeEmail() {
  const [searchParams] = useSearchParams();
  const cert_code = searchParams.get("cert_code");
  const user_id = searchParams.get("user_id");
  const userInfo = useRecoilValue(userInfoState);
  const navigate = useNavigate();
  const [cookie, removeCookie] = useCookies(["reEmail"]);

  useEffect(() => {
    if (cert_code !== null && user_id !== null) {
      if (user_id === userInfo.Userid) {
        const fetchData = async () => {
          try {
            let res = await axios.post(changeEmailCheckApi, {
              cert_code: cert_code,
              user_id: user_id,
              user_re_email: cookie.reEmail
            });
            if (res.data.result === 1) {
              alert(t("email_change_success"));
              removeCookie("reEmail", { path: "/", domain: ".valofe.com" });
              navigate("/membership/myinfo");
            } else if (res.data.result === -94 || res.data.result === -95) {
              alert(t("change_email_fail"));
            } else if (res.data.result === -96) {
              alert(t("email_authentication_complete_error1"));
            } else if (res.data.result === -97) {
              alert(t("email_authentication_complete_error2"));
            } else if (res.data.result === -99) {
              alert(t("email_authentication_complete_error3"));
            }
          } catch (error) {
            console.log("email verification", error);
          }
        };

        fetchData();
      }
    }
  }, []);

  return null;
}

export default SnsChangeEmail;
