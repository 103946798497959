import { defaultPreRegisterApi } from "api/libraryApi";
import { preRegisterPopUpState } from "atoms/uiAtom";
import { headersState, isLauncherState } from "atoms/userInfoAtom";
import { t } from "i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { loginTranslationKey } from "utils/CommonFunc";

export const useDefaultPreRegister = () => {
  const headers = useRecoilValue(headersState);
  const setRegisterPopUp = useSetRecoilState(preRegisterPopUpState);
  const [searchParams] = useSearchParams();
  const service_code = searchParams.get("service_code");
  const navigate = useNavigate();
  const isLauncher = useRecoilValue(isLauncherState);

  const api = () => {
    defaultPreRegisterApi(service_code || "", headers).then((res) => {
      const result = res.data.result;

      if (result === -99 && isLauncher) {
        window.app.vfunRequestLogin();
        return;
      }

      switch (result) {
        case -99:
          alert(t(loginTranslationKey));
          navigate(`/membership/signin?ret=${window.location.href}`);
          break;
        case -98:
          alert(t("there_is_no_pre_register"));
          break;
        case -97:
          alert(t("pre_register_are_not_possible"));
          break;
        case -96:
          alert(t("you_have_already_made_a_pre_regiter"));
          break;
        case 1:
          alert(t("pre_register_has_been_completed"));
          setRegisterPopUp(false);
          break;
        default:
          alert(t("my_inquiry_desc13"));
      }
    });
  };

  return api;
};
