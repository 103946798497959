import { t } from "i18next";
import Reaact from "react";
import { Link } from "react-router-dom";
import {
  JP_TERMS_ACT_CODE,
  JP_TERMS_LAW_CODE,
  VFUN_PRIVACY_CODE,
  VFUN_TERMS_CODE
} from "routers/contant";
import { redirectURL } from "utils/CommonFunc";
import { VFUN_SITE, YOUTUBE_URL } from "utils/config/Config";
import { useRecoilValue } from "recoil";
import { isLauncherState } from "atoms/userInfoAtom";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { langCodeState } from "atoms/contentsAtom";

function Footer() {
  const isLauncher = useRecoilValue(isLauncherState);
  const langCode = useRecoilValue(langCodeState);

  return (
    <footer id="valofe-footer" className="clearfix vfun">
      <div className="row-w clearfix" style={{ paddingTop: "35px" }}>
        <div className="sns pull-right">
          <ul>
            {/* <li>
              <Link
                to={window.location.href}
                className="lg"
                title="VFUN Lounge"
              />
            </li> */}
            <li>
              <Link
                to={window.location.href}
                onClick={() => redirectURL(YOUTUBE_URL, "_blank", isLauncher)}
                title="YOUTUBE"
              >
                <FontAwesomeIcon icon={faYoutube} className="yt" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="row-w clearfix">
        <div className="line clearfix"></div>
      </div>
      <div className="row-w clearfix cont">
        <div className="copyright pull-left">
          <div className="footer-logo" id="vfun">
            <span
              className="logo"
              onClick={() => redirectURL(VFUN_SITE, "_self", isLauncher)}
            />
          </div>
          <p>
            <span className="entity-c">©</span>
            <span className="year-of-copyrights">
              {new Date().getFullYear()}&nbsp;
            </span>
            VALOFE GLOBAL Ltd.
            All rights reserved.
          </p>
        </div>
        <nav className="nav pull-right">
          <ul>
            <li>
              <Link
                to={window.location.href}
                target="_self"
                onClick={() =>
                  redirectURL(`${VFUN_SITE}/company/intro`, "_self", isLauncher)
                }
              >
                {t("about_valofe")}
              </Link>
            </li>
            <li>
              <Link to={VFUN_PRIVACY_CODE}>{t("privacy_policy")}</Link>
            </li>
            <li>
              <Link to={VFUN_TERMS_CODE}>{t("terms_of_use")}</Link>
            </li>
          </ul>
          {langCode === "jp" && (
            <div className="jp-terms">
              <span>
                <Link to={JP_TERMS_LAW_CODE}>特定商取引法に基づく表示</Link>
              </span>
              <span>
                <Link to={JP_TERMS_ACT_CODE}>資金決済法に基づく表示</Link>
              </span>
            </div>
          )}
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
