import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { getAllGames, IAllGame, IAllGameData } from "api/api";
import { langCodeState } from "atoms/contentsAtom";
import SmallLoading from "components/loading/SmallLoading";
import "./AllGames.scoped.scss";
import TypeOne from "./TypeOne";
import TypeTwo from "./TypeTwo";

function AllGamesTab() {
  const langCode = useRecoilValue(langCodeState);
  const { isLoading, data } = useQuery<IAllGame>(["allgames", langCode], () =>
    getAllGames(langCode)
  );

  const [pcData, setPcData] = useState<IAllGameData[]>([]);
  const [mobileData, setMobileData] = useState<IAllGameData[]>([]);

  let pc = new Array();
  let mobile = new Array();
  useEffect(() => {
    if (data?.result === 1) {
      for (let i = 0; i < data.data.length; i++) {
        const type = data.data[i].game_type;

        if (type.includes("PC")) pc.push(data.data[i]);
        if (type.includes("Android") || type.includes("iOS"))
          mobile.push(data.data[i]);
      }
      setPcData(pc);
      setMobileData(mobile);
    }
  }, [isLoading]);

  const [viewTypeToggle, setViewTypeToggle] = useState(false);

  return (
    <section className="main-cont allGames" id="all-game">
      <div className="row-w clearfix ">
        <div className="title-area">
          <h1>{t("all_games")}</h1>
          <ul className="clearfix allGames-btn">
            <li onClick={() => setViewTypeToggle(false)}>
              <button className={`feed ${!viewTypeToggle && "on"}`} />
            </li>
            <li onClick={() => setViewTypeToggle(true)}>
              <button className={`list ${viewTypeToggle && "on"}`} />
            </li>
          </ul>
        </div>
        {!isLoading ? (
          !viewTypeToggle ? (
            <TypeOne pcData={pcData} mobileData={mobileData} />
          ) : (
            <TypeTwo pcData={pcData} mobileData={mobileData} />
          )
        ) : (
          <SmallLoading customheight="500px" />
        )}
      </div>
    </section>
  );
}

export default AllGamesTab;
