import axios from "axios";
import { BASE_PATH } from "./api";
import { headersType } from "atoms/userInfoAtom";

export const launcherStartApi = `${BASE_PATH}/api/auth/LauncherStart`;

export const eventTrackingApi = async (
  service_code: string,
  type: string,
  headers: headersType
) => {
  try {
    const res = await axios.get(
      `${BASE_PATH}/api/common/eventTracking/${service_code}/${type}`,
      {
        headers: headers
      }
    );
  } catch (error) {
    console.log("eventTrackingApi error");
  }
};
