import { atom, selector } from "recoil";
import { Service_type } from "../utils/CommonFunc";
import {
  createBooleanAtom,
  createNumberAtom,
  createStringAtom
} from "./commonAtom";

//// 유저 정보
export const loginState = createBooleanAtom("loginState", false);
export const isLauncherState = createBooleanAtom("isLauncherState", false);

export const isLauncherLoginSelector = selector({
  key: "isLauncherLoginSelector",
  get: ({ get }) => {
    const isLauncher = get(isLauncherState);
    const login = get(loginState);

    return isLauncher && !login;
  }
});

export const userIdState = createStringAtom("userIdState", "none");
export const ssoinfoState = createStringAtom("ssoinfoState", "none");
export const userBirthState = createStringAtom("userBirthState", "");
export const userEmailState = createStringAtom("userEmailState", "");
export const userReEmailState = createStringAtom("userReEmailState", "");
export const juminState = createStringAtom("juminState", "");
export const userNicknameState = createStringAtom("userNicknameState", "none");
export const userProfileKeyState = createStringAtom("userProfileKeyState", "");
export const userPointState = createNumberAtom("userPointState", 0);
export const userCountryState = createStringAtom("userCountryState", "");
export const emailAuthStatus = createStringAtom("emailAuthStatus", "N");
export const connectSiteState = createStringAtom("connectSiteState", "vfun");

// 유저 정보
export interface userInfoProps {
  Ssoinfo: string;
  Userid: string;
  Userbirth: string;
}

export const userInfoState = selector<userInfoProps>({
  key: "userInfoState",
  get: ({ get }) => {
    const ssoinfo = get(ssoinfoState);
    const userId = get(userIdState);
    const userBirth = get(userBirthState);

    const info = {
      Ssoinfo: ssoinfo,
      Userid: userId,
      Userbirth: userBirth
    };
    return info;
  }
});

// headersState는 api에 공통으로 들어가는 값이기 때문에 api header 넣는 용도로만 사용
export type headersType = {
  Ssoinfo: string;
  Userid: string;
  Userbirth: string;
  channelingType: string;
  // device: string;
  // token: string;
  // "device-id": string;
};

export const headersState = selector<headersType>({
  key: "headersState",
  get: ({ get }) => {
    const ssoinfo = get(ssoinfoState);
    const userId = get(userIdState);
    const userBirth = get(userBirthState);
    const connectSite = get(connectSiteState);
    // const isLauncher = get(isLauncherState);
    // const token = "";
    // const device_id = "";

    const info = {
      Ssoinfo: ssoinfo,
      Userid: userId,
      Userbirth: userBirth,
      channelingType: connectSite
      // device: isLauncher ? "launcher" : "web",
      // token: token,
      // "device-id": device_id
    };
    return info;
  }
});

//// 회원가입
interface IInputValue {
  id: string;
  password: string;
  passwordConfirm: string;
  firstName: string;
  lastName: string;
  email: string;
  birth: string;
  nickName: string;
  subject: string;
  description: string;
}

export const inputValueState = atom<IInputValue>({
  key: "inputValueState",
  default: {
    id: "",
    password: "",
    passwordConfirm: "",
    firstName: "",
    lastName: "",
    email: "",
    birth: "",
    nickName: "",
    subject: "",
    description: ""
  }
});

interface IValidText {
  id: string;
  password: string;
  passwordConfirm: string;
  name: string;
  email: string;
  birth: string;
  agree: string;
}

export const validTextState = atom<IValidText>({
  key: "validTextState",
  default: {
    id: "",
    password: "",
    passwordConfirm: "",
    name: "",
    email: "",
    birth: "",
    agree: ""
  }
});

interface IIsValid {
  id: boolean;
  password: boolean;
  passwordConfirm: boolean;
  isNext: boolean;
  name: boolean;
  email: boolean;
  birth: boolean;
  agree: boolean;
  isSignIn: boolean;
}

export const isValidState = atom<IIsValid>({
  key: "isValidState",
  default: {
    id: false,
    password: false,
    passwordConfirm: false,
    name: false,
    email: false,
    birth: false,
    isNext: false,
    agree: false,
    isSignIn: false
  }
});

interface IAgree {
  agree_all: boolean;
  agree_14: boolean;
  agree_term: boolean;
  agree_privacy: boolean;
  agree_notice: boolean;
}

export const agreeState = atom<IAgree>({
  key: "agreeState",
  default: {
    agree_all: false,
    agree_14: false,
    agree_term: false,
    agree_privacy: false,
    agree_notice: false
  }
});

//// 네이버 인증
export const isPrivacyAgreeState = createBooleanAtom(
  "isPrivacyAgreeState",
  false
);
export const naverAuthorizeUrl = createStringAtom("naverAuthorizeUrl", "none");

// RememberID
export const rememberIdState = createBooleanAtom("rememberIdState", false);

// 이메일 인증
export const isAuthConfirmState = createBooleanAtom(
  "isAuthConfirmState",
  false
);
