import { BASE_PATH } from "api/api";
import { isLauncherState } from "atoms/userInfoAtom";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useRecoilValue } from "recoil";
import { redirectURL } from "utils/CommonFunc";

export const useLogout = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const isLauncher = useRecoilValue(isLauncherState);

  const logoutApi = async () => {
    try {
      await axios.post(`${BASE_PATH}/api/vfun/logout_proc`);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const logout = () => {
    logoutApi();

    Object.keys(cookies).forEach((keys) => {
      if (cookies["rememberId"] === "Y") {
        if (keys !== "userid" && keys !== "rememberId") {
          if (keys !== "locale") {
            removeCookie(keys, { path: "/", domain: ".valofe.com" });
            // setCookie(keys, "", { path: "/" });
          }
        }
      } else {
        if (keys !== "locale") {
          removeCookie(keys, { path: "/", domain: ".valofe.com" });
          // setCookie(keys, "", { path: "/" });
        }
      }

      window.localStorage.removeItem("prevPath");
      if (isLauncher) {
        window.app.vfunRequestLogout();
      }
      redirectURL(window.location.href, "_self", isLauncher);
    });
  };

  return logout;
};
