import { useState, useCallback } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import {
  headersState,
  isLauncherLoginSelector,
  isLauncherState
} from "atoms/userInfoAtom";
import { redirectURL } from "utils/CommonFunc";
import icon from "utils/data/categoryIcon.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { getCombineMenu, ICombineMenu, IMenuData, ISub_Menu } from "api/api";
import SideMenuLoading from "components/loading/SideMenuLoading";
import { langCodeState } from "atoms/contentsAtom";
import { BILLING_CHARGE_URL } from "utils/config/Config";
import { useCookies } from "react-cookie";

function SideMenuList() {
  const { t } = useTranslation();
  const headers = useRecoilValue(headersState);
  const langCode = useRecoilValue(langCodeState);
  const isLauncherlogin = useRecoilValue(isLauncherLoginSelector);
  const isLauncher = useRecoilValue(isLauncherState);
  const [cookies] = useCookies();
  const service_code = cookies["service_code"];

  const { isLoading, data } = useQuery<ICombineMenu>(["menu", langCode], () =>
    getCombineMenu(headers, langCode)
  );

  // 카테고리 토글
  const dataLeng = data?.result === 1 ? data?.data.length : 0;
  const arr = Array.from([dataLeng], () => false);
  const [menuToggle, setMenuToggle] = useState(arr);
  const onClickMenuToggle = useCallback(
    (idx: number) => {
      setMenuToggle((prev) => ({
        ...prev,
        [idx]: !menuToggle[idx]
      }));
    },
    [arr]
  );

  // 오늘로부터 15일 전 나온 메뉴인가 판단
  const compareDate = (category: IMenuData) => {
    if (category !== null || category !== undefined) {
      for (let j = 0; j < category.sub_menus.length; j++) {
        let created = new Date(category.sub_menus[j].created_at);
        let today = new Date();
        let diff = created.getTime() - today.getTime();
        let diffDate = Math.abs(Math.ceil(diff / (1000 * 60 * 60 * 24)));
        if (diffDate <= 15) {
          return true;
        }
      }
      return false;
    }
  };

  const newContIcon = (date: string) => {
    let create_date = new Date(date);
    let today = new Date();

    let diff = create_date.getTime() - today.getTime();
    let diffDate = Math.abs(Math.ceil(diff / (1000 * 60 * 60 * 24)));

    if (diffDate <= 30) {
      return true;
    } else {
      return false;
    }
  };

  const categoryIcon = (name: string) => {
    let nameKeymatch = icon.sideMenuIcon.filter((i) => i.name === name);

    if (nameKeymatch) {
      return nameKeymatch[0].image_url;
    }
    return icon.sideMenuIcon[0].image_url;
  };

  const onClickMemTab = (url: string, target: string) => {
    if (isLauncherlogin) {
      window.app.vfunRequestLogin();
    } else {
      redirectURL(url, target, isLauncher);
    }
  };

  return (
    <div className="nav-list">
      <ul>
        {isLoading ? (
          <SideMenuLoading />
        ) : data?.result === 1 ? (
          data?.data.map((category: IMenuData, idx: number) =>
            category.menu_code === "vfun_lounge" ||
            category.menu_code === "vfun_ground" ? (
              <li
                key={idx}
                className={`nav-li ${menuToggle[idx] ? "on" : ""}`}
                onClick={() => onClickMenuToggle(idx)}
              >
                <Link to={window.location.href} className="has-ul">
                  <span className="icon color-theme">
                    <img
                      src={categoryIcon(category.menu_code)}
                      alt="menu-list"
                    />
                  </span>
                  {t(category.menu_code)}
                  {compareDate(category) ? (
                    <small className="new">N</small>
                  ) : null}
                  {category.sub_menu === "Y" && (
                    <span className="size">{category.sub_menus.length}</span>
                  )}
                  <span
                    className={`blit-arrow ${menuToggle[idx] ? "on" : ""}`}
                  />
                </Link>
                <ul className={`nav-list2 ${menuToggle[idx] ? "on" : ""}`}>
                  {menuToggle[idx]
                    ? category.sub_menus.map((j: ISub_Menu, idx: number) => (
                        <li key={idx}>
                          <Link
                            to="#"
                            onClick={() =>
                              redirectURL(j.target_url, j.target, isLauncher)
                            }
                          >
                            <FontAwesomeIcon icon={faAngleRight} />
                            {" " + j.title}
                            {newContIcon(j.created_at) ? (
                              <small className="new">N</small>
                            ) : null}
                          </Link>
                        </li>
                      ))
                    : null}
                </ul>
              </li>
            ) : category.menu_code === "membership" ? (
              <li
                key={idx}
                className={`nav-li ${menuToggle[idx] ? "on" : ""}`}
                onClick={() => onClickMenuToggle(idx)}
              >
                <Link to={window.location.href} className="has-ul">
                  <span className="icon color-theme">
                    <img
                      src={categoryIcon(category.menu_code)}
                      alt="menu-list"
                    />
                  </span>
                  {t(category.menu_code)}
                  {compareDate(category) ? (
                    <small className="new">N</small>
                  ) : null}
                  {category.sub_menu === "Y" && (
                    <span className="size">{category.sub_menus.length}</span>
                  )}
                  <span
                    className={`blit-arrow ${menuToggle[idx] ? "on" : ""}`}
                  />
                </Link>
                <ul className={`nav-list2 ${menuToggle[idx] ? "on" : ""}`}>
                  {menuToggle[idx]
                    ? category.sub_menus.map((j: ISub_Menu, idx: number) => (
                        <li key={idx}>
                          <Link
                            to="#"
                            onClick={
                              j.menu_code !== "gcoins"
                                ? () => onClickMemTab(j.target_url, j.target)
                                : () =>
                                    redirectURL(
                                      BILLING_CHARGE_URL(
                                        service_code || "vfun"
                                      ),
                                      "_self",
                                      isLauncher
                                    )
                            }
                          >
                            <FontAwesomeIcon icon={faAngleRight} />
                            {" " + t(j.menu_code)}
                            {newContIcon(j.created_at) ? (
                              <small className="new">N</small>
                            ) : null}
                          </Link>
                        </li>
                      ))
                    : null}
                </ul>
              </li>
            ) : (
              <li
                key={idx}
                className={`nav-li ${menuToggle[idx] ? "on" : ""}`}
                onClick={() => onClickMenuToggle(idx)}
              >
                <Link to={window.location.href} className="has-ul">
                  <span className="icon color-theme">
                    <img
                      src={categoryIcon(category.menu_code)}
                      alt="menu-list"
                    />
                  </span>
                  {t(category.menu_code)}
                  {compareDate(category) ? (
                    <small className="new">N</small>
                  ) : null}
                  {category.sub_menu === "Y" && (
                    <span className="size">{category.sub_menus.length}</span>
                  )}
                  <span
                    className={`blit-arrow ${menuToggle[idx] ? "on" : ""}`}
                  />
                </Link>
                <ul className={`nav-list2 ${menuToggle[idx] ? "on" : ""}`}>
                  {menuToggle[idx]
                    ? category.sub_menus.map((j: ISub_Menu, idx: number) =>
                        isLauncher && j.menu_code === "download" ? null : (
                          <li key={idx}>
                            <Link
                              to="#"
                              onClick={() =>
                                redirectURL(j.target_url, j.target, isLauncher)
                              }
                            >
                              <FontAwesomeIcon icon={faAngleRight} />
                              {" " + t(j.menu_code)}
                              {newContIcon(j.created_at) ? (
                                <small className="new">N</small>
                              ) : null}
                            </Link>
                          </li>
                        )
                      )
                    : null}
                </ul>
              </li>
            )
          )
        ) : null}
      </ul>
    </div>
  );
}

export default withTranslation()(SideMenuList);
