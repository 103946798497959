import React, { useEffect, useState } from "react";
import "./MultipleSliders.scss";
import Slider from "react-slick";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { videoPopUpState } from "atoms/uiAtom";
import { useMediaQuery } from "react-responsive";
import { useQuery } from "react-query";
import { getVideo, IVideo } from "api/mainApi";
import { videoIdState } from "atoms/contentsAtom";
import { dateFormType1, redirectURL } from "utils/CommonFunc";
import { YOUTUBE_URL } from "utils/config/Config";
import { t } from "i18next";
import VideoSlideLoading from "components/loading/VideoSlideLoading";
import { isLauncherState } from "atoms/userInfoAtom";

function VideosSlider() {
  const { isLoading, data } = useQuery<IVideo>(["video"], getVideo);
  const isLauncher = useRecoilValue(isLauncherState);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/platform.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const width940 = useMediaQuery({
    query: "screen and (max-width: 940px)"
  });

  const width640 = useMediaQuery({
    query: "screen and (max-width: 640px)"
  });

  const slidesCount = width940 ? (width640 ? 2 : 3) : 4;

  const settings = {
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: slidesCount,
    speed: 500,
    draggable: false,
    arrows: true
  };

  const setVideoPopUp = useSetRecoilState(videoPopUpState);
  const setVideoId = useSetRecoilState(videoIdState);
  const [videoCount, setVideoCount] = useState(0);

  const onClickVideo = (id: string) => {
    if (isLauncher) {
      redirectURL(id, "_blank", isLauncher);
    } else {
      setVideoId(id.split("/").slice(-1)[0]);
      setVideoPopUp(true);
    }
  };

  let dateArr: number[] = [];
  useEffect(() => {
    if (data?.result === 1 && data.data !== null) {
      const now = new Date();
      const aMonthAgo = new Date(now.setMonth(now.getMonth() - 1)).getTime();
      for (let v = 0; v < data.data.length; v++) {
        dateArr.push(new Date(data.data[v].published_at).getTime());

        if (aMonthAgo < dateArr[v]) {
          setVideoCount(dateArr.length);
        }
      }
    }
  }, [isLoading]);

  return (
    <section className="main-cont" id="videos-slider">
      <div className="row-w clearfix">
        <div className="title-area">
          <div className="wrap">
            <h1>{t("vfun_videos")}</h1>
            <Link
              to="#"
              className="main-more"
              onClick={() => redirectURL(YOUTUBE_URL, "_black", isLauncher)}
            >
              <FontAwesomeIcon icon={faPlus} className="faPlus" />
            </Link>
          </div>
          <div className="pull-right">
            <div
              className="g-ytsubscribe"
              data-channelid="UCBOhYvVCI0InwSoyCWsbPZw"
              data-layout="default"
              data-theme="dark"
              data-count="hidden"
            />
            <span className="wrap-page-num">
              Recently <span className="all-num">{videoCount}</span>VIDEOS
            </span>
          </div>
        </div>
        {!isLoading ? (
          <Slider {...settings}>
            {data?.result === 1 && data.data !== null
              ? data.data.map((i) => (
                  <div className="vd-list" key={i.video_url}>
                    <Link to="#" onClick={() => onClickVideo(i.video_url)}>
                      <div className="video-area">
                        <img
                          src={i.video_thumnail_medium}
                          alt={i.video_title}
                        />
                      </div>
                    </Link>
                    <dl>
                      <dt>{i.video_title}</dt>
                      <dd>{dateFormType1(i.published_at)}</dd>
                    </dl>
                  </div>
                ))
              : null}
          </Slider>
        ) : (
          <VideoSlideLoading />
        )}
      </div>
    </section>
  );
}

export default VideosSlider;
