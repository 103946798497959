import React, { useRef, useState, useEffect } from "react";
import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";
import {
  faDesktop,
  faList,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useSearchParams } from "react-router-dom";
import "./PageNav.scoped.scss";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  gameNameState,
  gameStyleState,
  gameTypeState,
  langCodeState
} from "atoms/contentsAtom";
import { useQuery } from "react-query";
import { getAllGames, getShopList, IAllGame, IShopList } from "api/api";
import { t } from "i18next";

interface ITypeList {
  type: string;
  className?: string;
  icon: IconDefinition;
  tit?: string;
}

interface IPageNav {
  title: string;
  type: string;
}

const styleList: ITypeList[] = [
  {
    type: "All",
    className: "list-icon",
    icon: faList,
    tit: "all"
  },
  {
    type: "PC",
    icon: faDesktop,
    tit: ""
  },
  {
    type: "Android",
    icon: faAndroid,
    tit: ""
  },
  {
    type: "iOS",
    icon: faApple,
    tit: ""
  }
];

function PageNav(props: IPageNav) {
  const { title, type } = props;
  const typeRef = useRef<HTMLUListElement>(null);
  const setGameStyle = useSetRecoilState(gameStyleState);
  const [gameType, setGameType] = useRecoilState(gameTypeState);
  const setGameName = useSetRecoilState(gameNameState);
  const [activeIndex, setActiveIndex] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const service_code = searchParams.get("service_code");
  const langCode = useRecoilValue(langCodeState);

  useEffect(() => {
    setGameType(service_code || "all");
  }, [service_code]);

  const onClicGameStyle = (idx: number, e: any) => {
    setGameStyle(e.target.id);
    setActiveIndex(idx);
  };

  const { isLoading: allLoading, data: allData } = useQuery<IAllGame>(
    ["allgames", langCode],
    () => getAllGames(langCode),
    {
      enabled: type === "select"
    }
  );

  const { isLoading: shopListLoading, data: shopListData } =
    useQuery<IShopList>(
      ["gameShopList", langCode],
      () => getShopList(langCode),
      {
        enabled: type === "select"
      }
    );

  const onSelectGame = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setGameType(e.target.value);
    setGameName(e.target.children[e.target.selectedIndex].innerHTML);
    setSearchParams({ service_code: e.target.value });
  };

  return (
    <section className="sorting-nav">
      <div className="row-w clearfix">
        <h1>{t(title)}</h1>
        {type === "btn" ? (
          <ul ref={typeRef}>
            {styleList.map((i, idx) => (
              <li
                className={`${activeIndex === idx ? "on" : ""}`}
                id={i.type}
                key={idx}
                onClick={(e) => onClicGameStyle(idx, e)}
              >
                <Link to="#" title={`${i.type} Games`}>
                  <FontAwesomeIcon icon={i.icon} className={i.className} />
                  {t(`${i.tit}`)}
                </Link>
              </li>
            ))}
          </ul>
        ) : type === "select" ? (
          <select onChange={onSelectGame} value={gameType}>
            <option value="all">{t("all")}</option>
            {title === "stamp_menu2"
              ? !shopListLoading
                ? shopListData?.result === 1
                  ? shopListData.data.map((i) => (
                      <option value={i.service_code} key={i.service_code}>
                        {i.game_title}
                      </option>
                    ))
                  : null
                : null
              : !allLoading
              ? allData?.result === 1
                ? allData.data.map((i) => (
                    <option value={i.service_code} key={i.idx}>
                      {i.game_title}
                    </option>
                  ))
                : null
              : null}
          </select>
        ) : null}
      </div>
    </section>
  );
}

export default PageNav;
