import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { loginState } from "atoms/userInfoAtom";

function PublicRoute() {
  const login = useRecoilValue(loginState);
  const from = localStorage.getItem("prevPath");

  return login ? <Navigate to={from || "/"} /> : <Outlet />;
}

export default PublicRoute;
