import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { launcherSignin } from "api/authApi";
import axios from "axios";
import { redirectURL } from "utils/CommonFunc";
import { useRecoilValue } from "recoil";
import { isLauncherState } from "atoms/userInfoAtom";

function LauncherSignin() {
  const [searchParams] = useSearchParams();
  const snstype = searchParams.get("snstype");
  const isLauncher = useRecoilValue(isLauncherState);
  const navigate = useNavigate();

  useEffect(() => {
    if (snstype !== null) {
      const fetchData = async () => {
        let res = await axios.get(launcherSignin(snstype));

        if (res.data.result === 1) {
          redirectURL(res.data.redirect_url, "_self", isLauncher);
        } else {
          navigate("/notfound");
        }
      };

      fetchData();
    } else {
      navigate("/");
    }
  }, []);

  return null;
}

export default LauncherSignin;
