import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation, withTranslation } from "react-i18next";
import { redirectURL, Service_type } from "utils/CommonFunc";
import { BILLING_CHARGE_URL } from "utils/config/Config";
import { headersState, isLauncherState } from "atoms/userInfoAtom";
import { getGcoin, IGCoin } from "api/api";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./VWallet.scoped.scss";

interface IWalletProps {
  isWalletOpen: boolean;
  className: string;
}

function VWallet(props: IWalletProps) {
  const { isWalletOpen, className } = props;
  const { t } = useTranslation();
  const headers = useRecoilValue(headersState);
  const [gcoin, setGcoin] = useState(0);
  const isLauncher = useRecoilValue(isLauncherState);
  const location = useLocation();
  const locationSlice = location.search
    .replaceAll("?", "&")
    .split("&")
    .filter((i) => i.includes("service_code"));
  const service_code =
    locationSlice.length === 0 ? "vfun" : locationSlice[0].split("=")[1];

  const { isLoading, data } = useQuery<IGCoin>(
    ["gcoin"],
    () => getGcoin(service_code || "", headers),
    {
      enabled: !!isWalletOpen
    }
  );

  useEffect(() => {
    if (data?.result !== undefined && data.result === 1) {
      if (Service_type) {
        setGcoin(data.data.bonus_cash + data.data.real_cash);
      } else {
        if (data.data.return_code[0] === "0000") {
          setGcoin(data.data.bonus_cash + data.data.real_cash);
        }
      }
    } else {
      setGcoin(0);
    }
  }, [data]);

  return (
    <ul
      id="vwallet"
      className={className}
      style={{
        display: isWalletOpen ? "block" : "none"
      }}
    >
      <li className="coin clearfix kr">
        <div className="clearfix">
          <span>V</span>
          <table>
            <tbody>
              <tr>
                <th>{Service_type ? t("gcoins") : t("vcoins")}</th>
              </tr>
              <tr>
                <td>
                  {isLoading ? (
                    <FontAwesomeIcon icon={faSpinner} pulse />
                  ) : (
                    gcoin.toLocaleString()
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Link
          to={window.location.href}
          className="btn-charge"
          target="_self"
          title={t("charge")}
          onClick={() =>
            redirectURL(BILLING_CHARGE_URL(service_code), "_self", isLauncher)
          }
        >
          {t("charge")}
        </Link>
      </li>
      {Service_type && (
        <li className="point clearfix">
          <div className="clearfix">
            <span>P</span>
            <table>
              <tbody>
                <tr>
                  <th>Points</th>
                </tr>
                <tr>
                  <td>
                    {!isLoading ? (
                      data?.result === 1 ? (
                        data.data.point
                      ) : (
                        0
                      )
                    ) : (
                      <FontAwesomeIcon icon={faSpinner} pulse />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Link
            to="#"
            className="btn-charge"
            onClick={() =>
              redirectURL(
                `${process.env.REACT_APP_PLATFORM_DNS}/stamp?service_code=all&type=stamp_daily`,
                "_self",
                isLauncher
              )
            }
            title={"GET"}
          >
            GET
          </Link>
        </li>
      )}
    </ul>
  );
}

export default withTranslation()(VWallet);
