import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GoogleOTPWrap from "components/OTPComponent/GoogleOTPWrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import axios from "axios";
import { verificationApi } from "api/otpApi";
import { useCookies } from "react-cookie";
import { emailAuthStatus, headersState } from "atoms/userInfoAtom";
import { useRecoilValue } from "recoil";
import parse from "html-react-parser";

function OTPLeaveVerify() {
  const [cookies] = useCookies();
  const service_code = cookies["service_code"];
  const headers = useRecoilValue(headersState);
  const [authNum, setAuthNum] = useState("");
  const emailAuth = useRecoilValue(emailAuthStatus);
  const navigate = useNavigate();

  useEffect(() => {
    if (emailAuth !== "Y") {
      navigate("/membership/sns_email_auth_confirm");
    }
  }, []);

  const onChangeNum = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAuthNum(e.target.value);
  };

  const authNumCheck = async () => {
    try {
      let res = await axios.post(
        verificationApi(service_code),
        {
          otpDigit: authNum,
          setStatus: 0
        },
        {
          headers: headers
        }
      );

      if (res.data.result === 1) {
        alert(t(res.data.msg));
        navigate("/otp/leave_complete");
      } else {
        alert(t(res.data.msg));
      }
    } catch (error) {
      console.log("otp", error);
    }
  };

  const onClickConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    authNumCheck();
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      authNumCheck();
    }
  };

  if (emailAuth !== "Y") {
    return null;
  }

  return (
    <GoogleOTPWrap>
      <section className="sub-cont mt0">
        <div className="row-w">
          <form onKeyDown={onKeyDown}>
            <article className="mt20">
              <h2 className="text-center">
                <span className="step">Step. 3</span>
                <br />
                <span>{t("g_otp_step3_tit")}</span>
              </h2>

              <div className="box step1 text-center">
                <p className="message">{parse(t("g_otp_step3_message"))}</p>
                <div className="clearfix">
                  <input
                    type="text"
                    className="otp-6 put-auth-num"
                    value={authNum}
                    onChange={onChangeNum}
                    maxLength={6}
                    placeholder="Enter verification number"
                  />
                </div>
              </div>
              <div className="wrap mb45">
                <button
                  onClick={() => navigate("/otp/leave_check")}
                  className="btn round-type long"
                >
                  <FontAwesomeIcon icon={faAngleLeft} /> {t("back")}
                </button>
                <button
                  className="btn round-type long"
                  onClick={onClickConfirm}
                >
                  {t("next")} <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </div>
            </article>
          </form>
        </div>
      </section>
    </GoogleOTPWrap>
  );
}

export default OTPLeaveVerify;
