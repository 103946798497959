import React, { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Link as ScrollMove } from "react-scroll";
import {
  LauncherLogin,
  loginTranslationKey,
  msgRedirectURL,
  redirectURL
} from "utils/CommonFunc";
import { useSetRecoilState } from "recoil";
import { menuOpenState } from "atoms/uiAtom";
import {
  connectSiteState,
  isLauncherState,
  loginState,
  userProfileKeyState
} from "atoms/userInfoAtom";
import { useRecoilValue } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faSignInAlt,
  faUser,
  faThLarge,
  faHouseChimney,
  faWallet,
  faUserAstronaut,
  faStar
} from "@fortawesome/free-solid-svg-icons";
import Footer from "./Footer";
import { EMAIL_VERIFICATION_CODE, SIGN_IN_PAGE_CODE } from "routers/contant";
import { useMediaQuery } from "react-responsive";
import VWallet from "components/common/VWallet/VWallet";

function FixedFooter() {
  const { t } = useTranslation();
  const setOpenSideMenu = useSetRecoilState(menuOpenState);
  const onClickSideMenu = () => {
    setOpenSideMenu((openSideMenu) => !openSideMenu);
  };
  const isLogin = useRecoilValue(loginState);
  const isLauncher = useRecoilValue(isLauncherState);
  const [isWalletOpen, setIsWalletOpen] = useState(false);
  const connectSite = useRecoilValue(connectSiteState);
  const userProfileKey = useRecoilValue(userProfileKeyState);

  const walletClick = () => {
    if (isLogin) {
      setIsWalletOpen((prev) => !prev);
    } else {
      if (isLauncher) {
        window.app.vfunRequestLogin();
      } else {
        msgRedirectURL(t(loginTranslationKey), SIGN_IN_PAGE_CODE, "_self");
      }
    }
  };

  const width320 = useMediaQuery({
    query: "screen and (max-width: 320px)"
  });

  return (
    <>
      <Footer />
      <footer id="vfun-fixed-footer">
        <div className="row-w clearfix">
          <div className="wrap">
            <ul className="menu">
              {connectSite === "vfun" && (
                <>
                  <li>
                    <Link
                      to={
                        process.env.REACT_APP_PLATFORM_DNS ||
                        "https://vfun.valofe.com"
                      }
                    >
                      <FontAwesomeIcon
                        icon={faHouseChimney}
                        className="FontAwesomeIconSpan"
                      />
                      <br />
                      {!width320 && t("home")}
                    </Link>
                  </li>
                  <li>
                    <Link to={window.location.href} onClick={onClickSideMenu}>
                      <FontAwesomeIcon
                        icon={faThLarge}
                        className="FontAwesomeIconSpan"
                      />
                      <br />
                      {!width320 && t("menu")}
                    </Link>
                  </li>

                  {!isLogin && (
                    <li>
                      <Link
                        to={
                          isLauncher ? window.location.href : SIGN_IN_PAGE_CODE
                        }
                        onClick={() => LauncherLogin(isLauncher)}
                      >
                        <FontAwesomeIcon
                          icon={faSignInAlt}
                          className="FontAwesomeIconSpan"
                        />
                        <br />
                        {!width320 && t("signin")}
                      </Link>
                    </li>
                  )}

                  <li className="user-wallet">
                    <Link
                      to={window.location.href}
                      id="fWallet"
                      onClick={walletClick}
                    >
                      <FontAwesomeIcon
                        icon={faWallet}
                        className="FontAwesomeIconSpan"
                      />
                      <br />
                      {!width320 && t("v_wallet")}
                    </Link>
                    <VWallet isWalletOpen={isWalletOpen} className="fV" />
                  </li>
                </>
              )}

              {isLogin && (
                <li className="my-space-btn">
                  <Link
                    to={window.location.href}
                    onClick={() =>
                      redirectURL(
                        `${process.env.REACT_APP_LOUNGE_DNS}/space/${userProfileKey}`,
                        "_self",
                        false
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faUserAstronaut}
                      className="FontAwesomeIconSpan"
                    />
                    <br />
                    {!width320 && `My space`}
                  </Link>
                  <FontAwesomeIcon icon={faStar} className="star-tag" bounce />
                </li>
              )}
            </ul>

            {/* 페이지 스크롤 */}
            <ul className="go-to">
              <li>
                <ScrollMove smooth={true} to="content" title="위로">
                  <FontAwesomeIcon
                    icon={faAngleDoubleUp}
                    className="FontAwesomeIconSpan"
                  />
                </ScrollMove>
              </li>
              <li>
                <ScrollMove
                  smooth={true}
                  to={
                    connectSite === "vfun" ? "valofe-footer" : "valofeKoFooter"
                  }
                  title="아래로"
                >
                  <FontAwesomeIcon
                    icon={faAngleDoubleDown}
                    className="FontAwesomeIconSpan"
                  />
                </ScrollMove>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default withTranslation()(FixedFooter);
