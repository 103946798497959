import React from "react";
import ContentLoader from "react-content-loader";

function VideoSlideLoading() {
  return (
    <ContentLoader
      speed={2} // 속도
      // width={530}
      // height={180}
      viewBox="0 0 500 100"
      backgroundColor="#27292d" // 배경색
      foregroundColor="#4b4b4b" // 요소 색
    >
      <rect x="0" y="5" width="115" height="90" />
      <rect x="130" y="5" width="115" height="90" />
      <rect x="260" y="5" width="115" height="90" />
      <rect x="390" y="5" width="115" height="90" />
    </ContentLoader>
  );
}

export default VideoSlideLoading;
