import React, { useEffect } from "react";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as offHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SIGN_IN_URL } from "utils/config/Config";
import { loginTranslationKey } from "utils/CommonFunc";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { headersState, isLauncherState } from "atoms/userInfoAtom";
import { detailLike } from "api/libraryApi";
import {
  isLikeLoadingState,
  isLikeState,
  likeCountState,
  popUpNewsState
} from "atoms/uiAtom";
import { t } from "i18next";
import GoBtn from "components/LibraryComponent/common/components/GoBtn";

interface ISocialBtn {
  addClassName?: string;
  lang_code: string;
  idx: number;
  like_count: number;
  user_like_status: number;
}

function SocialBtn(props: ISocialBtn) {
  const { addClassName, lang_code, idx } = props;
  const [searchParams] = useSearchParams();
  const service_code = searchParams.get("service_code");
  const headers = useRecoilValue(headersState);
  const [isLike, setIsLike] = useRecoilState(isLikeState);
  const setLikeCount = useSetRecoilState(likeCountState);
  const [isLikeLoading, setIsLikeLoading] = useRecoilState(isLikeLoadingState);
  const isLauncher = useRecoilValue(isLauncherState);
  const navigate = useNavigate();
  const setPopUpNews = useSetRecoilState(popUpNewsState);

  useEffect(() => {
    setIsLike(props.user_like_status === 0 ? false : true);
    setLikeCount(props.like_count);
  }, [props.like_count, props.user_like_status]);

  const LikeOnOff = (userLike: boolean) => {
    return userLike ? faHeart : offHeart;
  };

  const onClickLike = async () => {
    if (isLikeLoading === false) {
      setIsLikeLoading(true);
      try {
        let res = await axios.post(
          detailLike(service_code || "", String(props.idx)),
          {},
          {
            headers: headers
          }
        );
        setIsLikeLoading(false);
        if (res.data.result === 1) {
          setLikeCount(res.data.data.like_count);
          if (res.data.data.status) {
            setIsLike(true);
          } else {
            setIsLike(false);
          }
        } else if (res.data.result === -99) {
          if (isLauncher) {
            window.app.vfunRequestLogin();
          } else {
            alert(t(loginTranslationKey));
            setPopUpNews(false);
            navigate(SIGN_IN_URL("vfun"));
          }
        } else {
          alert(t("my_inquiry_desc13"));
        }
      } catch (error) {
        console.log("like", error);
      }
    }
  };

  return (
    <div className={`btn-area clearix ${addClassName}`}>
      <ul className="social">
        <li className="btn-like" onClick={onClickLike}>
          <FontAwesomeIcon
            icon={LikeOnOff(isLike)}
            className={isLike ? "on" : ""}
          />
        </li>
        <li>
          <GoBtn
            url={`${process.env.REACT_APP_LOUNGE_DNS}/page/${service_code}/${lang_code}/${idx}`}
            isShort={true}
            siteName="Lounge"
          />
        </li>
      </ul>
    </div>
  );
}

export default SocialBtn;
