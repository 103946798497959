import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { langCodeState, langListState, langState } from "atoms/contentsAtom";
import { useCookies } from "react-cookie";
import { isLauncherState } from "atoms/userInfoAtom";

function SwitchLanguageTab() {
  const { t, i18n } = useTranslation();
  const [isLang, setisLang] = useState(false);
  const [lang, setLang] = useRecoilState(langState);
  const [langCode, setLangCode] = useRecoilState(langCodeState);
  const [cookies, setCookie] = useCookies();
  const langList = useRecoilValue(langListState);
  const isLauncher = useRecoilValue(isLauncherState);
  const vfunLauncherLang = cookies["vfunLauncherLocale"];

  const hileLang = () => {
    setisLang(!isLang);
  };
  const changeLanguage = (language: string, text: string) => {
    setisLang(!isLang);
    i18n.changeLanguage(language);
    setLang(text);
    setLangCode(language);
  };

  useEffect(() => {
    if (isLauncher) {
      window.app.vfunSiteLangChange(langCode, vfunLauncherLang);
    }
  }, [isLang, langCode, vfunLauncherLang]);

  useEffect(() => {
    if (langList.length !== 0) {
      setCookie("locale", langCode, { path: "/", domain: ".valofe.com" });
    }
  }, [langCode, langList]);

  return (
    <div className="cbm-sel-lang" id="login-area" onClick={hileLang}>
      <Link
        to={window.location.href}
        className={`sel-tit ${isLang ? "on" : ""}`}
      >
        {t(lang)}
      </Link>
      <ul className="view2" style={{ display: `${isLang ? "block" : "none"}` }}>
        {langList.map((i, idx) => (
          <li key={idx}>
            <Link
              to={window.location.href}
              onClick={() => changeLanguage(i.lang, i.lang_code)}
            >
              {t(i.lang_code)}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default React.memo(SwitchLanguageTab);
