import React from "react";
import "./CoinInfo.scoped.scss";
import { BILLING_CHARGE_URL, VFUN_IMAGE_DNS } from "utils/config/Config";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { redirectURL } from "utils/CommonFunc";
import { useRecoilValue } from "recoil";
import { isLauncherState, loginState } from "atoms/userInfoAtom";
import { t } from "i18next";
import parse from "html-react-parser";
import { useCookies } from "react-cookie";

function CoinInfo() {
  const login = useRecoilValue(loginState);
  const isLauncher = useRecoilValue(isLauncherState);
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const serviceCode = cookies["service_code"];

  const goCoinPage = () => {
    if (isLauncher && !login) {
      window.app.vfunRequestLogin();
    } else if (!login) {
      navigate("/membership/signin");
    } else {
      redirectURL(BILLING_CHARGE_URL(serviceCode), "_self", isLauncher);
    }
  };

  return (
    <section className="gcoin-info" id="guideLink">
      <div className="row-w">
        <div className="clearfix">
          <img
            className="img-gcoin"
            src={`${VFUN_IMAGE_DNS}/web/vfun/images/common/gcoin/phone.png`}
            alt=" Buy easily and comfortably<br>Items wanted with G Coin."
          />
          <div className="desc">
            <span className="tit">{parse(t("g_coin_tit"))}</span>
            <button className="btn gcoin" onClick={goCoinPage}>
              <FontAwesomeIcon
                icon={faAngleDoubleRight}
                style={{ marginRight: "10px" }}
              />
              {t("g_coin_btn_purchase")}
            </button>
            <br />
            <strong className="define">{t("g_coin_is")} </strong>
            <p className="sentence">{parse(t("g_coin_desc"))}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CoinInfo;
