import { BASE_PATH } from "api/api";
import { refreshTokenApi } from "api/authApi";
import axios from "axios";
import { useState } from "react";
import { useCookies } from "react-cookie";

export const useRefreshToken = () => {
  const [cookies, setCookie] = useCookies();
  const [isWithinFiveMinutes, setIsWithinFiveMinutes] = useState(false);
  // 300000
  const fiveMinutesInMilliseconds = 5 * 60;
  const utc = Math.floor(Date.now() / 1000);

  const checkIfWithinFiveMinutes = async () => {
    const expiredTime = cookies["expired_at"];
    const timeDifference = Number(expiredTime) - utc;

    // 목표 시간이 5분 이내인지 확인
    if (timeDifference <= fiveMinutesInMilliseconds || timeDifference <= 0) {
      setIsWithinFiveMinutes(true);

      try {
        await axios.post(refreshTokenApi).then((res) => {
          if (res.data.result === 1) {
            setCookie("expired_at", res.data.data.expired_at, {
              path: "/",
              domain: ".valofe.com"
            });
          }
        });

        // 결과 값으로 나온 expiredTime 갱신 필요
      } catch (error) {
        console.log(error, "refresh");
      }
    } else {
      setIsWithinFiveMinutes(false);
    }
  };

  return checkIfWithinFiveMinutes;
};
