import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { SIGN_UP_PAGE_CODE } from "routers/contant";
import { useRecoilValue } from "recoil";
import { isLauncherState } from "atoms/userInfoAtom";

interface IProps {
  addclassname: string;
}

function LoginBtn(props: IProps) {
  const { t } = useTranslation();
  const isLauncher = useRecoilValue(isLauncherState);
  const navigate = useNavigate();

  const onClickSignIn = () => {
    if (isLauncher) {
      window.app.vfunRequestLogin();
    } else {
      navigate("/membership/signin?");
    }
  };

  const onClickSignUp = () => {
    if (isLauncher) {
      window.app.vfunRequestLogin();
    } else {
      navigate(SIGN_UP_PAGE_CODE);
    }
  };

  return (
    <ul className={`${props.addclassname} pull-right`}>
      <li className="login" onClick={onClickSignIn}>
        <Link to={window.location.href}>{t("signin")}</Link>
      </li>
      <li className="signup" onClick={onClickSignUp}>
        <Link to={window.location.href}>{t("signup")}</Link>
      </li>
    </ul>
  );
}

export default withTranslation()(LoginBtn);
