import { t } from "i18next";
import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { isLauncherState, loginState } from "atoms/userInfoAtom";
import { loginTranslationKey } from "utils/CommonFunc";

function PrivateRoute() {
  const login = useRecoilValue(loginState);
  const location = useLocation();
  const isLauncher = useRecoilValue(isLauncherState);

  useEffect(() => {
    if (isLauncher && !login) {
      window.app.vfunRequestLogin();
    } else {
      if (!login) {
        localStorage.setItem("prevPath", location.pathname);
        if (location.search !== "") {
          localStorage.setItem("prevPath", location.pathname + location.search);
        }
        alert(t(loginTranslationKey));
      }
    }
  }, [login, isLauncher]);

  return login ? (
    <Outlet />
  ) : (
    <Navigate to={isLauncher ? "/" : "/membership/signin?service_code=vfun"} />
  );
}

export default PrivateRoute;
